import React from 'react';
import { graphql } from 'gatsby';

import Hero from '../components/Hero';
import Layout from '../components/Layout/Layout';
import { Container } from '../components/Grid';
import Band from '../components/Band/Band';
import Typography from '../components/Typography';
import Contact from '../components/Contact/Contact';
import PageHeader from '../components/PageHeader/PageHeader';
import SEO from '../components/SEO';
import Tiles from '../components/Tiles/Tiles';

export default ({ data }) => (
    <Layout>
        <SEO
            title="Recruitment nurturing talent"
            description="Our comprehensive global network and research helps us get recruitment right, matching ability to opportunity to save our clients and our candidates time, money and resources"
        />
        <Hero
            title="What does Black Dot do?"
            mobileImage={data.mobileImage}
            desktopImage={data.desktopImage}
            logoColor="white"
            logoPosition="top-right"
        />
        <Band size="md">
            <Container>
                <Typography color="green">
                    <PageHeader>
                        We've no interest in churn and we’re committed to fitting the right people to the right roles
                    </PageHeader>
                    <p>
                        Our comprehensive global network and research helps us get this right, matching ability to
                        opportunity to save our clients and our candidates time, money and resources.
                    </p>
                    <p>
                        We’re committed to help nurturing rich talent and are active in a number of global initiatives.
                        By investing in the future of design and connecting people around the world, we bring people
                        closer together.
                    </p>
                </Typography>
            </Container>
        </Band>
        <Band>
            <Tiles />
        </Band>
        <Band size="md">
            <Container>
                <Contact heading="Let's connect" />
            </Container>
        </Band>
    </Layout>
);

export const query = graphql`
    query WhatPageQuery {
        desktopImage: file(relativePath: { eq: "unsplash-9qsK2QHidmg.jpg" }) {
            ...HeroBackgroundImage_Desktop
        }
        mobileImage: file(relativePath: { eq: "unsplash-sm-9qsK2QHidmg.jpg" }) {
            ...HeroBackgroundImage_Mobile
        }
    }
`;
